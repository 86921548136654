import { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { HList, VList, Select, Text, Card, Button, Title, Alert } from "@thetreep/components-library"
import Spinner from "../components/atoms/spinner"
import CardBody from "./card.body"
import CardHeader from "./card.header"

// Default Select Options
const defaultOptions = [
    {
        label: "Dégats des eaux",
        value: "water-damaged",
        uuid: 0,
    },
    {
        label: "Incendies",
        value: "fire-damaged",
        uuid: 1,
    },
    {
        label: "Fermeture administrative",
        value: "closed",
        uuid: 2,
    },
  ]

const Wrapper = styled.div`
    display: flex;
    width: 450px;
`

const Form = ({ hotelID }) => {
    const [ type, setType ] = useState({label: null, value: null})
    const [ hotel, setHotel ] = useState({error: {hasError: false, message: ""}, syncing: false, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
    const [ posted, setPosted ] = useState({error: {hasError: false, message: ""}, syncing: false, completed: false})

    useEffect( () => {
        const fetch = async () => {
            setHotel({error: {hasError: false, message: ""}, syncing: true, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
            try{
                const result = await axios.get(`https://trm-thetreep.herokuapp.com/hotels/${hotelID}`)
                setHotel({error: {hasError: false, message: ""}, syncing: false, completed: true, data: { ...result.data }})
            } catch(err) {
                setHotel({error: {hasError: true, message: "Une erreur est survenue"}, syncing: false, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
            }
        }
        fetch()
    }, [ hotelID ])

    const postForm = async () => {
        setPosted({ ...posted, syncing: true, completed: false })
        try{
            await axios.post(`https://trm-thetreep.herokuapp.com/incidents`, {
                ...hotel.data,
                description: type,
                type: "climat",
            })
            setPosted({ ...posted, syncing: false, completed: true })
        } catch(err) {
            setPosted({ ...posted, syncing: false, error: { hasError: true, message: err} })
        }
    }

    return(
        <Wrapper>
            <Card overflow="unset">
                <VList h="fit-content" w="100%">
                    <CardHeader>
                        <Title size="h2">Déclarer un incident</Title>
                        { !hotel.syncing && !hotel.error.hasError &&
                            <HList h="fit-content">
                                <Title size="h4">{ hotel.data.attributes.name }</Title>
                                <Text>{ `${hotel.data.attributes.address}` }</Text>
                            </HList>
                        }
                        { hotel.syncing && <Spinner /> }
                        { hotel.error.hasError && 
                            <Alert type="warn" size="medium">
                                { hotel.error.message }
                            </Alert>
                        }
                    </CardHeader>
                    {!posted.completed && <CardBody>
                        <HList w="100%" p="0 0 16px 0">
                            <Select
                                options={defaultOptions}
                                placeholder="Choose"
                                disabled={false}
                                active={false}
                                name="Incidents"
                                isFiltered={true}
                                maxItems={5}
                                id="hotel-incidents-select"
                                defaultValue={type}
                                onChange={(item) => setType(item)}
                            />
                        </HList>
                        <HList w="100%">
                            <Button
                                disabled={posted.syncing}
                                children="Déclarer"
                                loading={posted.syncing}
                                onClick={() => postForm()}
                                size="wide"
                            />
                        </HList>
                    </CardBody>}
                    {posted.completed && <CardBody>
                        Votre incident a bien été déclaré. Merci!
                    </CardBody>}
                </VList>
            </Card>
        </Wrapper>
    )
}

export default Form
