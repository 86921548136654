import { useState } from "react"
import styled from "styled-components"
import { ThemeProvider, theme } from "@thetreep/components-library"
import Form from "./form"

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const App = ({ hotelID }) => {
  const [state] = useState({
    theme
  })
  return (
    <Wrapper data-testid="app">
      <ThemeProvider initialStore={state.theme}>
          <Form hotelID={hotelID} />
      </ThemeProvider>
    </Wrapper>
  );
}

export default App;
